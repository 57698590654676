import React from "react";
import {useSelector} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import {EllipsisOutlined, HomeOutlined} from "@ant-design/icons";
import {useAppAbility} from "../../util/Can";


export const useNavigationItems = () => {
  const {authUser, initURL} = useSelector(({auth}) => auth);
  const CAPTAIN_PREFIX = "CAPTAIN_";
  const isKeyGroup = authUser?.roles?.filter(r => r.startsWith(CAPTAIN_PREFIX)).map(r => r.replace(CAPTAIN_PREFIX, ""))
  const rolesCurrent = authUser?.roles
  const {profileId} = authUser
  const ability = useAppAbility();

  const items = [
    {
      key: "home",
      label: <Link to={`/home`}>
        <IntlMessages id={`label.home`}/>
      </Link>,
      enabled: ability.can("access", "home"),
      hidden: rolesCurrent?.every(role => 
        !["MANAGEMENT", "CAPTAIN_", "SUPERVISOR", "ADMIN"].includes(role)
      ),
    },
    {
      key: "students",
      label: <Link to={`/students`}>
        <IntlMessages id={`label.students`}/>
      </Link>,
      enabled: ability.can("access", "students"),
      hidden: rolesCurrent?.every(role => 
        !["MANAGEMENT", "SUPERVISOR", "ADMIN"].includes(role)
      ),

    },
    {
      key: "groups",
      label: <Link to={`/groups`}>
        <IntlMessages id={`label.groups`}/>
      </Link>,
      enabled: ability.can("access", "groups"),
      hidden: rolesCurrent?.every(role => 
        !["MANAGEMENT", "SUPERVISOR", "ADMIN"].includes(role)
      ),
    },
    {
      key: "checkins",
      label: <Link to={`/checkins`}>
        <IntlMessages id={`label.checkIn`}/>
      </Link>,
      enabled: ability.can("access", "checkins"),
      hidden: rolesCurrent?.every(role => 
        !["MANAGEMENT", "SUPERVISOR", "ADMIN"].includes(role) 
      ),
    },
    {
      key: `group/${isKeyGroup}`,
      label: <Link to={`/group/${isKeyGroup}`}>
        <IntlMessages id={`label.mygroup`}/>
      </Link>,
      enabled: ability.can("access", "group"),
      hidden: rolesCurrent?.every(role => 
        !role.startsWith("CAPTAIN_")
      ),
    },
    {
      key: `checkin/${isKeyGroup}`,
      label: <Link to={`/checkin/${isKeyGroup}`}>
        <IntlMessages id={`label.checkIn`}/>
      </Link>,
      enabled: ability.can("access", "checkin"),
      hidden: rolesCurrent?.every(role => 
        !role.startsWith("CAPTAIN_")
      ),
    },
    {
      key: "events",
      label: <Link to={`/events`}>
        <IntlMessages id={`label.events`}/>
      </Link>,
      enabled: ability.can("access", "events"),
      hidden: rolesCurrent?.every(role => 
        !["MANAGEMENT", "SUPERVISOR", "ADMIN"].includes(role)
      ),

    },
    {
      key: "applications",
      label: <Link to={`/applications`}>
        <IntlMessages id={`label.applications`}/>
      </Link>,
      enabled: ability.can("access", "applications"),
      hidden: rolesCurrent?.every(role => 
        ![ "SUPERVISOR", "ADMIN"].includes(role)
      ),

    },
    {
      key: "admin",
      label: <Link to={`/admin`}>
        <IntlMessages id={`label.admin`}/>
      </Link>,
      enabled: ability.can("access", "admin"),
      hidden: rolesCurrent?.every(role => 
        ![ "SUPERVISOR", "ADMIN"].includes(role)
      ),

    },
    {
      key: "interviewer",
      label: <Link to={`/interviewer`}>
       Người phỏng vấn
      </Link>,
      enabled: ability.can("access", "interviewer"),
      hidden: rolesCurrent?.every(role => 
        ![ "SUPERVISOR", "ADMIN"].includes(role)
      ),

    },
    {
      key: "interviewer",
      label: <Link to={`/interviewer/${profileId}`}>
        Phỏng vấn
      </Link>,
      enabled: ability.can("access", "interviewer"),
      hidden: rolesCurrent?.every(role => 
        !["INTERVIEWER"].includes(role)
      ),

    },
  ].filter(item => item.hidden === false)

  return items;
}

const HorizontalNav = () => {
  const {pathname} = useSelector(({common}) => common);
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  const items = useNavigationItems()
  return (
    <div className=" gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block">
      <Menu
        selectedKeys={[selectedKeys]}
        mode="horizontal"
        className="nav-horizontal"
        style={{backgroundColor: "transparent", lineHeight: "80px", fontWeight: 500}}
        items={items}
        // overflowedIndicator={<EllipsisOutlined className={"gx-ml-2"}/>}
        disabledOverflow
      />
    </div>

  );
};

HorizontalNav.propTypes = {};

export default HorizontalNav;

